import React from 'react';

import Container from 'components/Container/Container';
import HeroText from 'components/HeroText/HeroText';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import TextLayoutContainer from 'components/TextLayoutContainer/TextLayoutContainer';

// import { banner } from 'styles/pages/common.module.scss';

const PostalPage = () => (
  <Layout fullWidth>
    <Seo title="Τρόποι αποστολής" />
    <HeroText title="ΑΠΟΣΤΟΛΗ" subtitle="ΤΡΟΠΟΙ ΑΠΟΣΤΟΛΗΣ" />
    <Container withPadding>
      <TextLayoutContainer>
        <h2>Aποστολές σε όλη την Ελλάδα και Κύπρο</h2>
        <p>
          Ο συνεργάτης μας για αποστολές σε όλη την Ελλάδα και Κύπρο είναι η
          SPEEDEX.
          <br />
        </p>
        <h2>Σε πόσες μέρες θα την παραλάβω?</h2>
        <p>
          Ο χρόνος αποστολής της παραγγελίας σας σε ολη την Ελλάδα είναι από 1
          έως και 3 εργάσιμες ημέρες από την ημέρα αποστολής της.
        </p>
        <p>
          Για τις δυσπρόσιτες περιοχές χρειάζονται 1 – 2 ημέρες επιπλέον
          <br /> Τα δέματα παραδίδονται από Δευτέρα έως και Παρασκευή 08:00 -
          20:00
        </p>
        <p>
          Στην περίπτωση που δεν καταστεί δυνατή η παράδοση της παραγγελίας σας
          ο αποστολέας θα σας αφήσει ένα σημείωμα για τον τρόπο παραλαβής και
          επικοινωνίας με το υποκατάστημα της SPEEDEX .
        </p>
        <p>
          <em>
            *Οι χρόνοι αποστολής ενδέχεται να μεταβληθούν λόγω αστάθμητων
            παραγόντων όπως: μη άμεση διαθεσιμότητας του προϊόντος, ειδικής
            επεξεργασίας, απεργίες, καθυστερήσεις μεταφορικών εταιριών, αργιών,
            εορτών κ.τ.λ.
          </em>
        </p>
        <h2>Μπορώ να εντοπίσω την παραγγελία μου?</h2>
        <p>
          Κάθε πελάτης που έχει δώσει ηλεκτρονική διεύθυνση (e-mail) στα
          στοιχεία του, την ημέρα αποστολής της παραγγελίας του παραλαμβάνει ένα
          μοναδικό αριθμό.
          <br /> Με τον αριθμό αυτό είναι δυνατή η παρακολούθηση της παραγγελίας
          σας στο σύστημα της SPEEDEX
          <a href="http://www.speedex.gr/isapohi.asp ">
            <strong>
              <span>εδώ</span>
            </strong>
          </a>
        </p>
      </TextLayoutContainer>
    </Container>
  </Layout>
);

export default PostalPage;
